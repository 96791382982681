<template>
  <div
    class="container noColor"
    v-title
    :data-title="$t('i18n.batchUpdate')"
    id="batchUpdate"
  >
    <el-card class="box-card">
      <template #header>
        <span>{{ $t("i18n.basicInfo") }}</span>
        <el-button class="fr" type="text" @click="$router.go(-1)">
          {{ $t("i18n.back") }}
        </el-button>
      </template>
      <div class="text item">
        <jl-form :columns="basicColumns" :option="btnOption"></jl-form>
      </div>
    </el-card>
    <el-card class="box-card m-t">
      <div class="text item">
        <jl-table
          :tableData="tableData"
          @init="init"
          :options="options"
          :columns="columns"
          :operates="operates"
          :total="total"
          :height="$defined.HEIGHT - 360 + 'px'"
          @handleSelectionChange="handleSelectionChange"
        >
        </jl-table>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import jlForm from "../../components/form";
import jlTable from "../../components/table";
export default {
  name: "EditBatch",
  components: {
    jlForm,
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const router = useRouter();
    const state = reactive({
      paging: {
        page: 1,
        pageSize: 10,
      },
      form: {},
      basicColumns: [
        {
          prop: "projectName",
          label: "projectName",
          type: "input",
          change: (val) => {
            setData("projectName", val);
          },
        },
        {
          prop: "elevatorType",
          label: "elevatorType",
          type: "select",
          props: { label: "code", value: "value" },
          data: [],
          uploadUrl: "elevator_variety",
          change: (val) => {
            setData("elevatorType", val);
          },
        },
        {
          prop: "controllerType",
          label: "controllerType",
          type: "input",
          change: (val) => {
            setData("controllerType", val);
          },
        },
        {
          prop: "station",
          label: "layerStationDoor",
          placeholder: t("i18n.stationFormat"),
          type: "input",
          rules: [
            {
              pattern: /^[1-9]\d{0,1}\/[1-9]\d{0,1}\/[1-9]\d{0,1}$/,
              message: t("i18n.stationNot"),
              trigger: "blur",
            },
          ],
          change: (val) => {
            setData("station", val);
          },
        },
        {
          prop: "brandName",
          label: "elevatorBrand",
          type: "select",
          props: { label: "name", value: "id" },
          data: [],
          change: (val) => {
            setData("brandName", val);
          },
        },
        {
          prop: "elevatorModel",
          label: "elevatorModel",
          type: "input",
          change: (val) => {
            setData("elevatorModel", val);
          },
        },
        {
          prop: "dutyPhone",
          label: "hourLineOnDuty",
          type: "input",
          change: (val) => {
            setData("dutyPhone", val);
          },
        },
        {
          prop: "usageType",
          label: "useOccasion",
          type: "select",
          props: { label: "code", value: "value" },
          data: [],
          change: (val) => {
            setData("usageType", val);
          },
        },
        {
          prop: "ratedWeight",
          label: "ratedLoad",
          type: "input",
          change: (val) => {
            setData("ratedWeight", val);
          },
        },
        {
          prop: "ratedSpeed",
          label: "ratedSpeed",
          type: "input",
          change: (val) => {
            setData("ratedSpeed", val);
          },
        },
        {
          prop: "controlMode",
          label: "controlMode",
          type: "select",
          props: { label: "locale", value: "id" },
          data: [
            { locale: "collectiveSelective", id: "1" },
            { locale: "parallel", id: "2" },
          ],
          change: (val) => {
            setData("controlMode", val);
          },
        },
        {
          prop: "hoistingHeight",
          label: "hoistingHeight",
          type: "input",
          change: (val) => {
            setData("hoistingHeight", val);
          },
        },
        {
          prop: "elevatorExtendDTO&driveMode",
          label: "driveMode",
          type: "input",
          change: (val) => {
            setData("driveMode", val);
          },
        },
        {
          prop: "elevatorExtendDTO&doorOpenMode",
          label: "doorOpenMode",
          type: "input",
          change: (val) => {
            setData("doorOpenMode", val);
          },
        },
        {
          prop: "elevatorExtendDTO&nominalSpeed",
          label: "nominalSpeed",
          type: "input",
          change: (val) => {
            setData("nominalSpeed", val);
          },
        },
        {
          prop: "elevatorExtendDTO&nominalWidth",
          label: "nominalWidth",
          type: "input",
          change: (val) => {
            setData("nominalWidth", val);
          },
        },
        {
          prop: "elevatorExtendDTO&tiltAngle",
          label: "tiltAngle",
          type: "input",
          change: (val) => {
            setData("tiltAngle", val);
          },
        },
        {
          prop: "elevatorExtendDTO&mainPower",
          label: "mainPower",
          type: "input",
          change: (val) => {
            setData("mainPower", val);
          },
        },
        {
          prop: "elevatorExtendDTO&sectionLength",
          label: "sectionLength",
          type: "input",
          change: (val) => {
            setData("sectionLength", val);
          },
        },
        {
          prop: "elevatorExtendDTO&cylinderNum",
          label: "cylinderNum",
          type: "input",
          change: (val) => {
            setData("cylinderNum", val);
          },
        },
        {
          prop: "elevatorExtendDTO&jackingType",
          label: "jackingType",
          type: "input",
          change: (val) => {
            setData("jackingType", val);
          },
        },
        {
          prop: "agentName",
          label: "agent",
          type: "input",
          change: (val) => {
            setData("agentName", val);
          },
        },
        {
          prop: "productionTime",
          label: "productionDate",
          type: "date",
          change: (val) => {
            setData("productionTime", val);
          },
        },
        {
          prop: "deliverTime",
          label: "deliveryTime",
          type: "date",
          change: (val) => {
            setData("deliverTime", val);
          },
        },
        {
          prop: "pleaseSelect",
          label: "pleaseSelect",
          type: "input",
          change: (val) => {
            setData("pleaseSelect", val);
          },
        },
        {
          prop: "userCompanyId",
          label: "buildingUsingParty",
          type: "remote",
          props: { label: "name", value: null },
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteCompanyMethod(val, "basicColumns", "userCompanyId");
          },
          change: (val) => {
            setData("userCompanyId", val);
          },
        },
        {
          prop: "installCompanyId",
          label: "installationCompany",
          type: "remote",
          props: { label: "name", value: null },
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteCompanyMethod(val, "basicColumns", "installCompanyId");
          },
          change: (val) => {
            setData("installCompanyId", val);
          },
        },
        {
          prop: "manufacturerId",
          label: "manufacturingCompany",
          type: "remote",
          props: { label: "name", value: null },
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteCompanyMethod(val, "basicColumns", "manufacturerId");
          },
          change: (val) => {
            setData("manufacturerId", val);
          },
        },
        {
          prop: "ownerCompanyId",
          label: "propertyCompany",
          type: "remote",
          props: { label: "name", value: null },
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteCompanyMethod(val, "basicColumns", "ownerCompanyId");
          },
          change: (val) => {
            setData("ownerCompanyId", val);
          },
        },
        {
          prop: "regulatorId",
          label: "regulator",
          type: "remote",
          props: { label: "name", value: null },
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteCompanyMethod(val, "basicColumns", "regulatorId");
          },
          change: (val) => {
            setData("regulatorId", val);
          },
        },
        {
          prop: "repairCompanyId",
          label: "repairCompany",
          type: "remote",
          props: { label: "name", value: null },
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteCompanyMethod(val, "basicColumns", "repairCompanyId");
          },
          change: (val) => {
            setData("repairCompanyId", val);
          },
        },
        {
          prop: "maintainerId",
          label: "maintenanceCompany",
          type: "remote",
          props: { label: "name", value: null },
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteCompanyMethod(val, "basicColumns", "maintainerId");
          },
          change: (val) => {
            setData("maintainerId", val);
          },
        },
        {
          prop: "maintenanceManager",
          label: "manager",
          type: "input",
          change: (val) => {
            setData("maintenanceManager", val);
          },
        },
        {
          prop: "maintenanceName",
          label: "maintenancePerson",
          type: "input",
          change: (val) => {
            setData("maintenanceName", val);
          },
        },
      ],
      elevatorDetail: "",
      btnOption: [
        {
          show: false,
        },
      ],
      tableData: [],
      total: 0,
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: true, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "searchType",
          type: "select",
          data: proxy.$config.search,
          props: { label: "locale", value: "value" },
          rows: false,
          search: true,
        },
        {
          prop: "searchValue",
          type: "input",
          rows: false,
          search: true,
        },
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
          formatter: (row) => {
            return `<a>${row.number}</a>`;
          },
          method: (row) => {
            router.push({
              path: "/elevator/addEditElevator",
              query: { flag: 1, id: row.id },
            });
          },
        },
        {
          prop: "alias",
          label: "projectAlias",
          align: "left",
        },
        {
          prop: "projectName",
          label: "projectName",
          align: "left",
          width: 300,
        },
        {
          prop: "elevatorType",
          label: "elevatorType",
          align: "center",
        },
      ],
      operates: {
        // 列操作按钮
        width: 170,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "batchUpdate",
            icon: "el-icon-edit",
            class: "transparent",
            show: proxy.$defined.btnPermission("编辑电梯"),
            type: "outer",
            method: () => {
              batchUpdate();
            },
          },
        ],
      },
      selectItem: [],
    });

    const init = async (params) => {
      state.loading = true;
      const { data } = await proxy.$api.system.elevatorPage(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const setData = (key, val) => {
      state.form[key] = val instanceof Date ? proxy.$defined.format(val) : val;
    };

    const getBrandData = async () => {
      let { data } = await proxy.$api.monitor.brandsAll({ name: "" });
      state.basicColumns.map((item) => {
        if (item.prop === "brandName") {
          item.data = data;
        }
      });
    };
    getBrandData();

    const getUsageTypeData = async () => {
      let { data } = await proxy.$api.common.getLineageByCode("usage_type");
      state.basicColumns.map((item) => {
        if (item.prop === "usageType") {
          item.data = data.lineages;
        }
      });
    };
    getUsageTypeData();

    const remoteCompanyMethod = (val, column, key) => {
      var index = state[column].findIndex((item) => {
        return item.prop === key;
      });
      if (val) {
        state[column][index].loading = true;
        state[column].map((item) => {
          item.isUpdate = false;
        });
        setTimeout(() => {
          proxy.$api.system.getAllCompany({ name: val }).then((data) => {
            state[column][index].data = data.data;
            state[column][index].loading = false;
          });
        }, 1000);
      } else {
        state[column][index].data = [];
      }
    };

    const handleSelectionChange = (val) => {
      state.selectItem = val;
    };

    const batchUpdate = async () => {
      // 批量更新
      if (JSON.stringify(state.form) == "{}") {
        proxy.$defined.tip("请填写您需要批量更新的字段！");
      } else if (state.selectItem.length == 0) {
        proxy.$defined.tip(t("i18n.selectElevators"));
      } else {
        let arr = JSON.parse(JSON.stringify(state.selectItem));
        arr.map((item) => {
          for (let key in state.form) {
            item.elevatorExtendDTO = item.elevatorExtendDTO || {};
            item.elevatorExtendDTO.elevatorId = item.id;
            if (key.indexOf("&") > -1) {
              item.elevatorExtendDTO[key.split("&")[1]] = state.form[key];
            } else {
              item[key] = state.form[key];
            }
          }
        });
        await proxy.$api.system.batchUpdate(arr);
        proxy.$defined.tip(
          t("i18n.batchUpdate") + t("i18n.success"),
          "success"
        );
        init(state.paging);
      }
    };

    return {
      ...toRefs(state),
      setData,
      remoteCompanyMethod,
      init,
      handleSelectionChange,
      batchUpdate,
    };
  },
};
</script>

<style lang="scss">
#batchUpdate {
  .form-button {
    width: 100%;
    margin: 0;
    text-align: center;
  }
  .el-form--inline {
    .el-input {
      line-height: unset;
    }
  }
}
</style>
